.navigation-bar {
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: large;
}

.navigation-links {
    display: flex;
    gap: 20px;
    list-style: none ;
    padding-top: 15px;
}

.navigation-link:hover {
    color: rgb(15, 180, 6);
    text-decoration: underline;
    text-decoration-color: white;
}

.navigation-link {
    color: rgb(160 30 30);
    text-decoration: none;
    /*text-decoration-color: white;*/
}

.navigation-icons {
    font-size: larger;
    cursor: pointer;
    display: none;
}

.navigation-item {
    color : orange;
    border : none;
    border-bottom : 4px ridge;
    border-radius: 10px;
    padding: 5px;
    border-color: orange;
    text-decoration: none;
}

.navigation-item:hover {
    color : #ff0000;
}

.navigation-item.active {
    color: #ff0000;
    border-color: #ff0000;
}

@media screen and (max-width: 768px) {
    .navigation-icons {
        display: flex;
    }

    .navigation-bar {
        justify-content: end;
        text-align: center;
        padding-right: 10px;
    }

    .navigation-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 40px;
        background-color: black;
        padding: 1rem 0;
        width: 100%;
        right: 0px;
        gap: 10px;
    }

    .navigation-links.active {
        display: flex;
    }

    .navigation-item{
        padding-top: 10px;
        border-radius: 10px;
    }
}