input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type=number] {
    -moz-appearance: textfield;
}

@-moz-document url-prefix() {
    .fullsizedbutton {
      width: -moz-available
    }
  }